const headers = [
    {
        text: 'Todos',
        align: 'left',
        value: 'all',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Crear',
        align: 'left',
        value: 'create',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Ver',
        align: 'left',
        value: 'show',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Editar',
        align: 'left',
        value: 'update',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Borrar',
        align: 'left',
        value: 'delete',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    
]

const modules = [
  // {label:"Root", submodules:[ {key:"root", label: "Administrador"} ]},
    {
        props: {
            headers: [
                {
                    text: 'Obras',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
            
        },
        label: "Obras",
        submodules: [
            
            { key: "buildings", label: "Obras", notIn: ["create", "cancel"]  },
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Compras',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Compras",
        submodules: [
           
            { key: "suppliers", label: "Proveedores", notIn: []  },
            { key: "products", label: "Materias primas", notIn: []  },
            
            { key: "requisitions", label: "Requisiciones", notIn: []  },
            { key: "purchaseOrders", label: "Órdenes de compra", notIn: []  },
           
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Ventas',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Ventas",
        submodules: [
           
            { key: "clients", label: "Clientes", notIn: []  },
            { key: "bigProducts", label: "Productos", notIn: []  },
            // { key: "sellers", label: "Vendedores", notIn: []  },
            { key: "quotes", label: "Cotizaciones", notIn: []  },
            { key: "saleOrders", label: "Ordenes de trabajo", notIn: []  },
           
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Contabilidad',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Contabilidad",
        submodules: [
            { key: "banks", label: "Bancos", notIn: []  },
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Accesos',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Accesos",
        submodules: [
           
            { key: "users", label: "Usuarios", notIn: []  },
            { key: "roles", label: "roles", notIn: []  },
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Catálogos',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Catálogos",
        submodules: [
            { key: "catalog_bank", label: "Bancos", notIn: []  },
            { key: "catalog_unit", label: "Unidades",notIn: [] }
            
        ],
    },
    
];

export default modules;
